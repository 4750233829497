<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        entity="monthly_statement"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="!situation.locked ? remove : null"
      >
        <h1 class="title" cy-data="cy-situation-title">
          <breadcrumb
            location="/sst/situations"
            :text="$t('entities.situation')"
          ></breadcrumb>
          {{ situation.market && situation.market.edifice }} ({{
            situation.date | date
          }})
        </h1>

        <template slot="actions">
          <button
            v-if="
              !situation.locked &&
              getRights($entitiesName.MonthlyStatement).update &&
              situation.paidAt === null
            "
            class="button is-success"
            @click="markAsPaid"
          >
            {{ $t("situations.markPaid") }}
          </button>
          <button
            v-if="
              situation.locked &&
              userRoleOptions.includes(
                $roleOptions.MONTHLY_STATEMENTS.DANGEROUSLY_REVERT
              )
            "
            class="button is-danger"
            @click="dangerMarkAsUnpaid"
          >
            {{ $t("situations.cancelPayment") }}
          </button>
          <form
            v-if="!editMode"
            class="inline-form"
            method="POST"
            target="_blank"
            :action="`${API}/situation/${id}/pdf`"
          >
            <button type="submit" class="button is-success downloadButton">
              {{ $t("common.download") }}
            </button>
            <input type="hidden" name="token" :value="authToken" />
          </form>
        </template>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                @click="currentTab = 0"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 1 }"
                @click="currentTab = 1"
              >
                <a>{{ $t("common.documents") }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="panel-block is-block no-border"
          v-if="currentTab === 0 && situation && situation.market"
        >
          <div class="columns">
            <div class="column is-half">
              <many2one-field
                :label="$t('entities.contractorAgreement')"
                v-model="situation.market"
                :fetch="$api.fetchMarkets"
                reference="edifice"
                v-on="{
                  click: getRights($entitiesName.SubcontractorMarket).read
                    ? (item) => $router.push('/sst/market/' + item.id)
                    : null,
                }"
                entity="subcontractor_market"
                :columns="marketColumns"
                :inline="true"
                :edit="editMode"
                readonly
                required
                :hasFilter="true"
                v-validate="{ required: true }"
                name="market2one"
                cy-data="market"
                :error="errors.has('market2one')"
              ></many2one-field>
              <field :label="$t('common.agency')" readonly>
                <restricted-link
                  :entity="$entitiesName.Agency"
                  v-if="situation.market.agency"
                  :to="`/sst/agency/${situation.market.agency.id}`"
                >
                  <agency-display :value="situation.market.agency" />
                </restricted-link>
              </field>
              <field :label="$t('common.project')" readonly
                ><span cy-data="field-project-display">{{
                  situation.market.project.name
                }}</span></field
              >
              <field :label="$t('common.date')" readonly
                ><span cy-data="field-date-display">{{
                  situation.date | date
                }}</span></field
              >
              <field :label="$t('common.vat')" readonly
                ><span cy-data="field-vat-display">{{
                  situation.market.tva
                }}</span></field
              >
              <field
                readonly
                :label="$t('markets.vatTypes.reverseCharge')"
                isCheckbox
                :isChecked="situation.market.reverseCharge"
              />
              <field :label="$t('common.payment')" readonly
                ><span cy-data="field-payment-display">{{
                  situation.market.paymentType
                }}</span></field
              >
              <text-field
                cy-data="sap"
                :label="$t('situations.sapReference')"
                v-model="situation.sap"
              />
              <field cy-data="status" :label="$t('common.status')" readonly>
                {{ situation.status }}
              </field>
              <date-field
                cy-data="theoretical-payment-date"
                :label="$t('situations.theoreticalPaymentDate')"
                v-model="situation.theoreticalPaymentDate"
                :mustBeAfter="situation.date"
              />
              <!--
              <field label="Montant Total cumulé a date" readonly>
                <span v-if="situation.totalCumulative">{{ situation.totalCumulative }} €</span>
              </field>
              -->
            </div>
            <div class="column is-half">
              <div class="groupLines">
                <field
                  class="line"
                  :label="$t('situations.currentMonthAmount')"
                  readonly
                >
                  <span
                    cy-data="field-wt-amount-month-display"
                    v-if="situation.totalAmount || situation.totalAmount === 0"
                    >{{ situation.totalAmount | priceEUR }}</span
                  >
                </field>
              </div>

              <div class="groupLines">
                <!-- If depositType is not "Non concerné" -->
                <field
                  class="line"
                  :label="$t('situations.rgAmount')"
                  readonly
                  v-if="hasRg"
                >
                  <span
                    cy-data="field-wt-amount-rg-display"
                    v-if="situation.rgamount || situation.rgamount === 0"
                    >{{ situation.rgamount | priceEUR }}</span
                  >
                  <span
                    v-if="situation.rgamount < 0"
                    class="alert-icon"
                    title="Ce montant est négatif, le calcul de la situation mensuelle peut être incorrecte !"
                  >
                    <i class="fa fa-exclamation-triangle has-text-danger" />
                  </span>
                </field>

                <!-- Bond amount -->
                <field
                  class="line"
                  :label="$t('situations.bondAmount')"
                  readonly
                  v-if="hasDeposit"
                >
                  <span
                    cy-data="field-bond-amount-display"
                    v-if="situation.depositUsed || situation.depositUsed === 0"
                    >{{ situation.depositUsed | priceEUR }}</span
                  >
                  <span
                    v-if="situation.depositUsed < 0"
                    class="alert-icon"
                    title="Ce montant est négatif, le calcul de la situation mensuelle peut être incorrecte !"
                  >
                    <i class="fa fa-exclamation-triangle has-text-danger" />
                  </span>
                </field>

                <!-- Wihheld amount -->
                <field
                  class="line"
                  :label="$t('situations.withheldAmount')"
                  readonly
                  v-if="hasDeposit"
                >
                  <span
                    cy-data="field-withheld-amount-display"
                    v-if="situation.rgToRelease || situation.rgToRelease === 0"
                    >{{ situation.rgToRelease | priceEUR }}</span
                  >
                  <span
                    v-if="situation.rgToRelease < 0"
                    class="alert-icon"
                    title="Ce montant est négatif, le calcul de la situation mensuelle peut être incorrecte !"
                  >
                    <i class="fa fa-exclamation-triangle has-text-danger" />
                  </span>
                </field>
              </div>

              <div class="groupLines">
                <!-- If depositType is not "Non concerné" -->
                <field
                  class="line"
                  :label="$t('situations.amountWoRg')"
                  readonly
                  v-if="hasRg && !hasDeposit"
                >
                  <span
                    cy-data="field-wt-amount-rg-warranty-display"
                    v-if="situation.totalAmount || situation.totalAmount === 0"
                    >{{ situation.totalAmountWithoutRG | priceEUR }}</span
                  >
                </field>
              </div>

              <div class="groupLines">
                <!-- RG released -->
                <field
                  class="line"
                  :label="$t('situations.releasedAmount')"
                  readonly
                >
                  <span
                    cy-data="field-amount-rg-free-wt-display"
                    v-if="situation.rgreleased || situation.rgreleased === 0"
                    >{{ situation.rgreleased | priceEUR }}</span
                  >
                </field>
              </div>

              <div class="groupLines">
                <!-- TOTAL without VAT -->
                <field
                  class="line"
                  :label="$t('situations.totalAmountWoVat')"
                  readonly
                >
                  <span
                    cy-data="field-total-amount-wt-display"
                    v-if="situation.totalAmount || situation.totalAmount === 0"
                    >{{ situation.totalAmountWithReleasedRG | priceEUR }}</span
                  >
                </field>

                <!-- TVA computation -->
                <field
                  class="line"
                  :label="$t('situations.vatAmount')"
                  readonly
                  v-if="!situation.market.reverseCharge"
                >
                  <span
                    cy-data="field-vat-amount-display"
                    v-if="situation.tvaamount || situation.tvaamount === 0"
                    >{{ situation.tvaamount | priceEUR }}</span
                  >
                </field>
                <field
                  class="line"
                  style="margin-bottom: 5px"
                  :label="$t('situations.reverseChargeAmount')"
                  readonly
                  v-else
                >
                  <span
                    cy-data="field-reverse-charge-amount-display"
                    v-if="situation.tvaamount || situation.tvaamount === 0"
                    >{{ situation.tvaamount | priceEUR }}</span
                  >
                </field>

                <!-- Total amount All tax included -->
                <field
                  class="line"
                  :label="$t('situations.totalAmount')"
                  readonly
                >
                  <span
                    cy-data="field-total-amount-display"
                    v-if="
                      situation.totalAmountTTC || situation.totalAmountTTC === 0
                    "
                    >{{ situation.totalAmountTTC | priceEUR }}</span
                  >
                </field>
              </div>

              <table class="table is-striped is-full-width is-narrow">
                <thead>
                  <tr>
                    <th>{{ $t("situations.attachmentNo") }}</th>
                    <th>{{ $t("situations.releasedAmount") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="att in situation.releasedAttachments"
                    :key="`att_${att.id}`"
                  >
                    <td>
                      <router-link
                        v-if="att.id"
                        :to="'/sst/attachement/' + att.id"
                        >{{ att.reference }}</router-link
                      >
                    </td>
                    <td>{{ att.rgReleased | priceEUR }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="panel-block is-block no-border" v-if="currentTab === 1">
          <document-manager
            :objectName="$entitiesName.MonthlyStatement"
            :objectId="id"
            :deletable="getRights($entitiesName.MonthlyStatement).update"
          ></document-manager>
        </div>
      </div>

      <div
        class="panel infos"
        v-if="situation && situation.market"
        :key="`attachements_${id}`"
      >
        <panel-header
          :title="$t('common.attachments')"
          @add="addAttachement"
          :fetch="$api.fetchAttachementsByAgency.bind(this, id)"
          :columns="attachementPanelColumms"
          multiSelect
        >
          <button
            v-if="
              !situation.locked &&
              getRights($entitiesName.MonthlyStatement).update
            "
            slot="activator"
            class="button is-small is-success"
          >
            <i class="fa fa-plus"></i>
          </button>
        </panel-header>
        <flexi-table
          :loader="(callback) => callback(attachements)"
          :columns="attachementTableColumns"
          :showIndex="true"
          class="is-full-width"
          v-model="attachements"
          ref="attachementTable"
          :hasActions="true"
        >
          <template slot="dataRows" slot-scope="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>
                <restricted-link
                  :entity="$entitiesName.Attachement"
                  :to="'/sst/attachement/' + item.id"
                >
                  {{ item.reference }}
                </restricted-link>
              </td>
              <td class="nowrap has-text-right" style="width: 100px">
                {{ item.amount | priceEUR }}
              </td>
              <td class="nowrap has-text-right" style="width: 100px">
                {{ item.totalRg | priceEUR }}
              </td>
              <td class="nowrap has-text-right" style="width: 150px">
                {{ item.depositUsed | priceEUR }}
              </td>
              <td>
                <template v-for="(warranty, idx) in item.warranties">
                  <restricted-link
                    :key="warranty.id + '' + idx"
                    v-if="warranty.releaseMs"
                    container="span"
                    :entity="$entitiesName.MonthlyStatement"
                    :to="'/sst/situation/' + warranty.releaseMs.id"
                  >
                    {{ warranty.releaseMs.id }}-{{
                      warranty.releaseMs.market.edifice
                    }}
                    ({{ warranty.releaseMs.date | date }})
                  </restricted-link>
                </template>
              </td>
              <td>{{ item.statusWF && item.statusWF.node.name }}</td>
              <td slot="action">
                <restricted-link
                  container="span"
                  linkClass="button is-primary is-small"
                  entity="attachement"
                  :to="'/sst/attachement/' + item.id"
                >
                  <i class="fa fa-search"></i>
                </restricted-link>
                <span
                  v-if="
                    !situation.locked &&
                    getRights($entitiesName.MonthlyStatement).update
                  "
                  class="button is-danger is-small"
                  @click="removeAttachementFromStatement(item.id)"
                >
                  <i class="fa fa-trash"></i>
                </span>
              </td>
            </tr>
          </template>
        </flexi-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "situation",
  props: ["id"],
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      userRoleOptions: "auth/getOptions",
    }),
    hasRg() {
      return this.attachements.some(
        (att) => att.warranties && att.warranties.length > 0
      );
    },
    hasDeposit() {
      return this.attachements.some((att) => {
        return att.warranties.some((warranty) => warranty.deposit);
      });
    },
  },
  data() {
    return {
      currentTab: 0,
      situation: {},
      attachements: [],
      agencyMany2OneColumns: {
        address: "Address",
      },
      marketColumns: {
        "project.name": this.$t("common.project"),
        "agency.company.name": "Etablissement",
        edifice: this.$t("common.edifice"),
      },
      attachementTableColumns: [
        {
          title: this.$t("common.reference"),
        },
        {
          title: this.$t("common.amount"),
          class: "has-text-centered",
        },
        {
          title: this.$t("common.rg"),
          class: "has-text-centered",
        },
        {
          title: this.$t("common.includingDepositUsed"),
          class: "has-text-centered",
        },
        {
          title: this.$t("situations.releaseSituation"),
        },
        {
          title: "Status",
        },
      ],
      attachementPanelColumms: {
        reference: {
          title: this.$t("common.reference"),
          filter: "",
        },
        "order.reference": {
          title: this.$t("common.order"),
          filter: "",
        },
        "statusWF.node.name": this.$t("common.status"),
        updatedAt: "Updated",
      },
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
    };
  },
  mounted() {
    this.fetchSituation();
  },
  watch: {
    id() {
      this.fetchSituation();
    },
    attachements() {
      if (this.$refs.attachementTable) this.$refs.attachementTable.fetch();
    },
  },
  methods: {
    fetchSituation() {
      axios.get(`/situation/${this.id}`).then((response) => {
        this.situation = response.data;
        this.attachements = response.data.attachements;
      });
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          return axios.put(`/situation/${this.id}`, this.situation).then(() => {
            this.fetchSituation();
            this.$store.dispatch("states/setEdition", false);
          });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      axios.get(`/situation/${this.id}`).then((response) => {
        this.situation = response.data;
        this.$store.dispatch("states/setEdition", false);
      });
    },
    remove() {
      const self = this;
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/situation/${self.id}/delete`).then((response) => {
            if (response.data.success) {
              this.$router.replace("/sst/situations");
            }
          })
      );
    },
    markAsPaid() {
      const self = this;
      let preceedingAttNotPaid = false;
      this.situation.releasedAttachments.forEach((a) => {
        if (!a.paid) {
          preceedingAttNotPaid = true;
        }
      });
      if (preceedingAttNotPaid) {
        return this.$awn.confirm(
          "Vous ne pouvez pas passer cette situation mensuelle comme payée car un ou plusieurs attachements dont la retenue de garantie est à payer dans cette situation mensuelle, n'ont pas encore été payés.",
          () => {}
        );
      }
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir marquer cette situation mensuelle comme payée ?",
        () =>
          axios.post(`/situation/${self.id}/paid`).then((response) => {
            if (response.data) {
              this.fetchSituation();
            }
          })
      );
    },
    dangerMarkAsUnpaid() {
      const self = this;
      if (this.situation.releaseMs && this.situation.releaseMs.paidAt) {
        return this.$awn.confirm(
          "Vous ne pouvez pas passer cette situation mensuelle comme non payée car sa retenue de garantie a déjà été payée dans une autre situation mensuelle.",
          () => {}
        );
      }
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir annuler le paiement de cette situation mensuelle ?",
        () =>
          axios
            .post(`/situation/${self.id}/dangerously_unpay`)
            .then((response) => {
              if (response.data) {
                this.fetchSituation();
              }
            })
      );
    },
    removeAttachementFromStatement(attId) {
      return axios
        .delete(`/situation/${this.id}/attachement/${attId}`)
        .then((response) => {
          if (response.data) {
            this.fetchSituation();
          }
        });
    },
    addAttachement(attachements) {
      const attachementIds = attachements.map((att) => att.id);
      axios
        .post(`/situation/${this.id}/attachement`, attachementIds)
        .then(() => {
          this.fetchSituation();
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.inline-form {
  display: inline;
}

.is-full-width {
  flex: 1;
  width: 100%;
}

.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.groupLines {
  margin-bottom: 18px;
  .line {
    margin-bottom: 4px;
  }
}

.error {
  border: 2px solid red;
}

.alert-icon {
  cursor: pointer;
  padding-left: 5px;
}
</style>
