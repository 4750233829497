var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$entitiesName.Order}},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("common.retentions")))])]),_c('div',{staticClass:"filters has-background-primary"},[_c('div',{staticClass:"filter-item"},[_c('many2one-field',{attrs:{"label":_vm.$t('common.projects'),"fetch":_vm.$api.fetchProjects,"reference":"name","entity":"project","columns":{ name: _vm.$t('expressions.projectName') },"hasFilter":true,"inline":false,"edit":true},model:{value:(_vm.projects),callback:function ($$v) {_vm.projects=$$v},expression:"projects"}})],1),_c('div',{staticClass:"filter-item"},[_c('many2one-field',{attrs:{"label":_vm.$t('entities.contractorAgreement'),"fetch":_vm.$api.fetchMarkets,"reference":"edifice","entity":"subcontractor_market","columns":_vm.marketColumns,"hasFilter":true,"inline":false,"edit":true},model:{value:(_vm.markets),callback:function ($$v) {_vm.markets=$$v},expression:"markets"}})],1),_c('div',{staticClass:"filter-item"},[_c('many2one-field',{attrs:{"label":_vm.$t('entities.oscarSubProject'),"fetch":_vm.$api.fetchOscarSubProjects,"reference":"name","entity":"sheet","columns":{
            arcgisName: _vm.$t('oscarSubProjects.columns.arcgisName'),
            nroName: _vm.$t('common.nro'),
            offerIdPm: _vm.$t('common.pm'),
          },"hasFilter":true,"inline":false,"edit":true},model:{value:(_vm.subProjects),callback:function ($$v) {_vm.subProjects=$$v},expression:"subProjects"}})],1),_c('div',{staticClass:"filter-item"},[_c('many2one-field',{attrs:{"label":_vm.$t('common.imputations'),"fetch":_vm.$api.fetchImputations,"reference":"eotp","entity":"imputation","columns":{ eotp: _vm.$t('common.eotp') },"hasFilter":true,"inline":false,"edit":true},model:{value:(_vm.imputations),callback:function ($$v) {_vm.imputations=$$v},expression:"imputations"}})],1),(_vm.userRoleOptions.includes(_vm.$roleOptions.OS.EDIT_FINANCIAL_INFO))?_c('div',{staticClass:"filter-item form"},[_c('div',[_c('selector-field',{attrs:{"options":_vm.fetchDateTypes,"edit":"","pKey":'key',"inline":false},model:{value:(_vm.dateSelected),callback:function ($$v) {_vm.dateSelected=$$v},expression:"dateSelected"}}),_c('date-field',{attrs:{"edit":"","inline":false},model:{value:(_vm.newDate),callback:function ($$v) {_vm.newDate=$$v},expression:"newDate"}})],1),_c('div',{staticClass:"button is-primary",attrs:{"disabled":!_vm.newDate || _vm.selectedOrders.length === 0},on:{"click":_vm.setDatesToOrders}},[_vm._v(" "+_vm._s(_vm.$t("common.save"))+" ")])]):_vm._e()]),_c('datatable',{ref:"orderWarantiesList",attrs:{"fetch":function (p) { return _vm.$api.fetchOrderWarranties(p, _vm.params); },"enableDownload":true,"objectName":"orders/warranties","order":"DESC","selector":"","additional-download-params":_vm.params},scopedSlots:_vm._u([{key:"reference",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('warranties.orderReference'),"sortable":true}},[(item.id)?_c('restricted-link',{attrs:{"entity":"order","to":("/sst/order/" + (item.id))}},[_vm._v(" "+_vm._s(item.reference)+" ")]):_c('span',[_vm._v(_vm._s(item.reference))])],1)}},{key:"follower",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('orders.columns.orderManagedBy'),"sortable":true}},[_vm._v(" "+_vm._s(item.follower && item.follower.name)+" ")])}},{key:"statusWF",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('common.status')}},[_vm._v(" "+_vm._s(item.nodeName)+" ")])}},{key:"endDate",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('warranties.orderEndDate'),"sortable":true,"nofilter":""}},[_vm._v(" "+_vm._s(item.endDate)+" ")])}},{key:"deliveryDate",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('warranties.deliveryDate'),"sortable":true,"nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("reverseDate")(item.deliveryDate))+" ")])}},{key:"releaseDate",fn:function(ref){
          var item = ref.item;
return _c('div',{attrs:{"title":_vm.$t('warranties.releaseDate'),"sortable":true,"nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("reverseDate")(item.releaseDate))+" ")])}},{key:"amount",fn:function(ref){
          var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.attAmount'),"help":_vm.$t('warranties.attAmountHelp'),"nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.alreadyInvoiced))+" ")])}},{key:"rg",fn:function(ref){
          var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('common.rg'),"nofilter":"","help":_vm.$t('warranties.rgHelp')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.rg))+" ")])}},{key:"depositUsed",fn:function(ref){
          var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.bondRg'),"nofilter":"","help":_vm.$t('warranties.bondRgHelp')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.depositUsed))+" ")])}},{key:"rgToRelease",fn:function(ref){
          var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.withheldRg'),"nofilter":""}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.rgToRelease))+" ")])}},{key:"rgReleased",fn:function(ref){
          var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.rgReleased'),"nofilter":"","help":_vm.$t('warranties.rgReleasedHelp')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.rgReleased))+" ")])}},{key:"rgleft",fn:function(ref){
          var item = ref.item;
return _c('div',{staticClass:"has-text-right",attrs:{"title":_vm.$t('warranties.rgToRelease'),"nofilter":"","help":_vm.$t('warranties.rgToReleaseHelp')}},[_vm._v(" "+_vm._s(_vm._f("priceEUR")(item.rgLeft))+" ")])}}]),model:{value:(_vm.selectedOrders),callback:function ($$v) {_vm.selectedOrders=$$v},expression:"selectedOrders"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }