<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$entitiesName.Deposit">
        <h1 class="title">{{ $t("common.deposits") }}</h1>
      </page-header>

      <div class="filters has-background-primary">
        <div class="filter-item">
          <many2one-field
            :label="$t('common.project')"
            v-model="projects"
            :fetch="$api.fetchProjects"
            reference="name"
            entity="project"
            :columns="{ name: $t('expressions.projectName') }"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field
            :label="$t('common.contractorAgreement')"
            v-model="markets"
            :fetch="$api.fetchMarkets"
            reference="edifice"
            entity="subcontractor_market"
            :columns="marketColumns"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
        <div class="filter-item">
          <many2one-field
            :label="$t('common.reference')"
            v-model="references"
            :fetch="$api.fetchDepositReferences"
            reference="reference"
            entity="sheet"
            :columns="{ reference: $t('common.reference') }"
            :hasFilter="true"
            :inline="false"
            :edit="true"
          ></many2one-field>
        </div>
      </div>

      <datatable
        :fetch="(p) => $api.fetchDeposits(p, params)"
        ref="depositList"
        :enableDownload="true"
        :objectName="$entitiesName.Deposit"
        order="DESC"
        selector
      >
        <div
          slot="market"
          slot-scope="{ item }"
          :title="$t('common.contractorAgreement')"
          :sortable="true"
        >
          <restricted-link
            :entity="$entitiesName.SubcontractorMarket"
            :to="`/sst/market/${item.market && item.market.id}`"
          >
            {{ item.market && item.market.edifice }}
          </restricted-link>
        </div>
        <!-- <div slot="order" slot-scope="{ item }" title="Commande" :sortable="true">
          <restricted-link :entity="$entitiesName.Order" :to="`/sst/order/${item.order && item.order.id}`">
            {{ item.order && item.order.reference }}
          </restricted-link>
        </div> -->
        <div
          slot="reference"
          slot-scope="{ item }"
          :title="$t('common.reference')"
          :sortable="true"
        >
          {{ item.reference }}
        </div>
        <div
          slot="amount"
          slot-scope="{ item }"
          :title="$t('deposits.depositAmount')"
          :sortable="true"
          class="has-text-right"
        >
          {{ item.amount | priceEUR }}
        </div>
        <div
          slot="depositUsed"
          slot-scope="{ item }"
          :title="$t('deposits.depositUsed')"
          nofilter
          class="has-text-right"
        >
          {{ item.depositUsed | priceEUR }}
        </div>
        <div
          slot="depositLeft"
          slot-scope="{ item }"
          :title="$t('deposits.amountLeft')"
          nofilter
          class="has-text-right"
        >
          {{ (item.amount - item.depositUsed) | priceEUR }}
        </div>
        <div
          slot="reimbursementDate"
          slot-scope="{ item }"
          :title="$t('deposits.reimbursementDate')"
          :sortable="true"
          type="date"
        >
          {{ item.reimbursementDate | reverseDate }}
        </div>
      </datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../components/Datatable";
import AgencyDisplay from "../../components/displays/AgencyDisplay";

export default {
  name: "Deposits",
  components: {
    Datatable,
  },
  data() {
    return {
      marketColumns: {
        edifice: this.$t("markets.columns.edifice"),
        "agency.company.name": this.$t("entities.agency"),
        agency: {
          title: this.$t("agencies.columns.edifice"),
          component: AgencyDisplay,
          filter: "",
        },
      },
      projects: [],
      markets: [],
      references: [],
    };
  },
  computed: {
    params() {
      return (
        "projects=" +
        this.projectIds +
        "&markets=" +
        this.marketIds +
        "&references=" +
        this.referenceIds
      );
    },
    projectIds() {
      if (this.projects.length === 0) return [];

      return this.projects.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
    marketIds() {
      if (this.markets.length === 0) return [];

      return this.markets.reduce((acc, val) => {
        return [...acc, val.id];
      }, []);
    },
    referenceIds() {
      if (this.references.length === 0) return [];

      return this.references.reduce((acc, val) => {
        return [...acc, val.reference];
      }, []);
    },
  },
  watch: {
    projects() {
      this.$refs.depositList.refresh();
    },
    markets() {
      this.$refs.depositList.refresh();
    },
    references() {
      this.$refs.depositList.refresh();
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  padding: 10px;
  margin-bottom: 15px;
}
.filter-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px;
  overflow: hidden;
  & > * {
    margin: 0 !important;
  }
  height: 115px;
  &:hover {
    height: initial;
    min-height: 115px;
  }
}
</style>
