<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        entity="order"
        :edit="!order.locked ? edit : null"
        :save="save"
        :cancel="cancel"
        :remove="
          order.statusWF &&
          order.statusWF.node.nodeCode == WorkflowCodes.OS.NODE.DRAFT
            ? remove
            : null
        "
      >
        <h1 class="title" cy-data="cy-order-title">
          <breadcrumb location="/sst/orders" text="Commande"></breadcrumb>
          {{ order.reference }}
        </h1>

        <template slot="actions" v-if="!isLoading">
          <!-- Only the financial validator can send the order to Oracle if it hasn't been sent yet -->
          <button
            v-if="order.canSendToOracle"
            class="button is-warning"
            @click="sendToOracle"
            :class="{ 'is-loading': sendToOracleLoader }"
            :disabled="sendToOracleLoader"
          >
            {{ $t("orders.resendToOracle") }}
          </button>
          <button
            v-if="
              !editMode &&
              currentTab === 1 &&
              !order.locked &&
              getRights($entitiesName.Order).update
            "
            class="button is-primary"
            @click="importClick"
          >
            {{ $t("common.import") }}
          </button>
          <form
            v-if="!editMode && currentTab === 1"
            class="inline-form"
            method="POST"
            target="_blank"
            :action="`${API}/order/${id}/export?sort=${sorting}`"
          >
            <button type="submit" class="button is-primary">
              {{ $t("common.exporter") }}
            </button>
            <input type="hidden" name="token" :value="authToken" />
          </form>
          <form
            v-if="
              !editMode &&
              userRoleOptions.includes(
                $roleOptions.OS.DISPLAYEDIT_FINANCIAL_INFO
              )
            "
            class="inline-form"
            method="POST"
            target="_blank"
            :action="`${API}/order/${id}/pdf`"
          >
            <button type="submit" class="button is-success downloadButton">
              {{ $t("common.download") }}
            </button>
            <input type="hidden" name="token" :value="authToken" />
          </form>

          <button
            v-if="
              !followerEdition &&
              (currentTab === 0 || currentTab === 3) &&
              order.locked &&
              getRights($entitiesName.Order).update
            "
            class="button is-primary"
            @click="followerEdition = true"
          >
            {{ $t("common.edition") }}
          </button>
          <button
            v-if="followerEdition && (currentTab === 0 || currentTab === 3)"
            class="button is-danger"
            @click="followerEdition = false"
          >
            {{ $t("common.cancel") }}
          </button>
          <button
            v-if="followerEdition && (currentTab === 0 || currentTab === 3)"
            class="button is-success"
            @click="save"
          >
            {{ $t("common.save") }}
          </button>
        </template>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li
                :class="{ 'is-active': currentTab === 0 }"
                cy-data="cy-os-infos-tab"
                data-test="sel-os-infos-tab"
                @click="currentTab = 0"
              >
                <a>{{ $t("common.information") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 1 }"
                cy-data="cy-os-prestations-tab"
                data-test="sel-os-prestations-tab"
                @click="currentTab = 1"
              >
                <a>{{ $t("common.prestations") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 2 }"
                cy-data="cy-os-documents-tab"
                data-test="sel-os-documents-tab"
                @click="currentTab = 2"
              >
                <a>{{ $t("common.documents") }}</a>
              </li>
              <li
                :class="{ 'is-active': currentTab === 3 }"
                cy-data="cy-os-attachements-tab"
                data-test="sel-os-attachments-tab"
                @click="currentTab = 3"
              >
                <a>{{ $t("common.attachments") }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="panel-block is-block no-border"
          v-if="currentTab === 0 && !isLoading"
        >
          <div class="columns">
            <div class="column is-half" v-if="order">
              <field
                cy-data="reference"
                :label="$t('common.reference')"
                v-model="order.reference"
                readonly
              ></field>
              <text-field
                cy-data="details"
                :label="$t('orders.columns.complement')"
                v-model="order.name"
                required
                :edit="
                  (editMode || followerEdition) &&
                  userRoleOptions.includes(
                    $roleOptions.OS.EDIT_FINANCIAL_INFO
                  ) &&
                  !order.sentSST
                "
              ></text-field>
              <field :label="$t('orders.columns.contractNumber')" readonly>
                <span
                  cy-data="field-subcontractor-contract-number"
                  v-if="order.market"
                  >{{ order.market.contractNumber }}</span
                >
              </field>
              <text-field
                :label="$t('orders.columns.mainClient')"
                v-model="order.mainClient"
              ></text-field>
              <text-field
                cy-data="client-market"
                :label="$t('orders.columns.clientMarket')"
                v-model="order.clientMarket"
              ></text-field>
              <text-field
                cy-data="client-bdc"
                :label="$t('orders.columns.bdcCustomer')"
                v-model="order.clientBdc"
              ></text-field>

              <text-field
                :label="$t('orders.columns.deliveryDuration')"
                v-model="order.executionPeriod"
              ></text-field>

              <many2one-field
                :label="$t('orders.columns.sstMarket')"
                v-model="order.market"
                :fetch="$api.fetchActiveMarkets"
                reference="edifice"
                :columns="marketColumns"
                @click="(item) => $router.push('/sst/market/' + item.id)"
                entity="subcontractor_market"
                required
                :hasFilter="true"
                v-validate="{ required: true }"
                name="market2one"
                :error="errors.has('market2one')"
                cy-data="market"
                :readonly="true"
              ></many2one-field>

              <field :label="$t('common.agency')" readonly>
                <restricted-link
                  container="span"
                  v-if="order.agency"
                  entity="agency"
                  :to="'/sst/agency/' + order.agency.id"
                >
                  <agency-display :value="order.agency" />
                </restricted-link>
              </field>

              <many2one-field
                cy-data="oscar-sub-project"
                v-if="order.isFTTH"
                :label="$t('entities.oscarSubProject')"
                v-model="order.oscarSubProject"
                :fetch="
                  $api.fetchOscarSubProjectsByProject.bind(this, projectId)
                "
                reference="name"
                entity="oscar_project"
                :columns="{
                  'oscarProject.name': $t('entities.oscarProject'),
                  name: $t('entities.oscarSubProject'),
                  arcgisName: $t('oscarSubProjects.columns.arcgisName'),
                }"
                :edit="editMode || followerEdition"
                :hasFilter="true"
              >
              </many2one-field>

              <field
                cy-data="arcgis-name"
                v-if="order.isFTTH && order.oscarSubProject"
                :label="$t('oscarSubProjects.columns.arcgisName')"
                readonly
                >{{
                  order.oscarSubProject && order.oscarSubProject.arcgisName
                }}</field
              >
              <field
                cy-data="nro"
                v-if="order.isFTTH && order.oscarSubProject"
                :label="$t('common.nro')"
                readonly
                >{{
                  order.oscarSubProject && order.oscarSubProject.nroName
                }}</field
              >
              <field
                cy-data="pm"
                v-if="order.isFTTH && order.oscarSubProject"
                :label="$t('common.pm')"
                readonly
                >{{
                  order.oscarSubProject && order.oscarSubProject.offerIdPm
                }}</field
              >

              <many2one-field
                :label="$t('common.eotp')"
                v-model="order.imputation"
                :fetch="fetchFilteredImputation"
                reference="eotp"
                :columns="imputationColumns"
                entity="user"
                :hasFilter="true"
                v-validate="{ required: true }"
                :inline="true"
                cy-data="eotp"
                :edit="
                  (editMode || followerEdition) &&
                  userRoleOptions.includes($roleOptions.OS.EDIT_FINANCIAL_INFO)
                "
                :error="errors.has('imp2one')"
              ></many2one-field>

              <field
                cy-data="oracle-project-name"
                v-if="order.oracleProject"
                :label="$t('entities.oracleProject')"
                readonly
                >{{ order.oracleProject && order.oracleProject.name }}</field
              >

              <field
                cy-data="oracle-task-name"
                v-if="order.oracleTask"
                :label="$t('entities.oracleTask')"
                readonly
                >{{ order.oracleTask && order.oracleTask.name }}</field
              >

              <field :label="$t('common.bpu')" readonly>
                <restricted-link
                  cy-data="bpu"
                  :entity="$entitiesName.BpuSst"
                  container="span"
                  :to="`/sst/bpu/${order.bpu.id}`"
                  v-if="order.market"
                >
                  <span cy-data="field-bpu-display">{{ order.bpu.name }}</span>
                </restricted-link>
              </field>

              <many2one-field
                :label="$t('entities.orderTemplate')"
                v-model="order.orderTemplate"
                :fetch="fetchFilteredOrderTemplate"
                reference="name"
                entity="os_template"
                @click="(item) => $router.push('/os/template/' + item.id)"
                :columns="orderTemplateColumns"
                :hasFilter="true"
                :edit="editMode"
                cy-data="os-template"
                data-test="sel-os-template"
              ></many2one-field>

              <field :label="$t('entities.projectType')" readonly>
                <span
                  cy-data="field-project-type-display"
                  v-if="order.market"
                  >{{ order.projectType }}</span
                >
              </field>

              <field :label="$t('orders.wfStatus')" readonly>
                <span v-if="order.statusWF">
                  <router-link
                    cy-data="field-workflow-status-display"
                    :to="'/node/' + order.statusWF.node.id"
                    :title="order.statusWF.createdAt | datetime"
                    >{{ order.statusWF.node.name }}</router-link
                  >
                  ({{ order.statusWF.createdAt | fromNow }})
                </span>
              </field>

              <date-field
                cy-data="end-date"
                :label="$t('orders.columns.completionDate')"
                v-model="order.endDate"
                required
              ></date-field>

              <field :label="$t('expressions.creationDate')" readonly
                ><span cy-data="field-creation-date-display">{{
                  order.createdAt | datetime
                }}</span></field
              >

              <field :label="$t('common.creator')" readonly>
                <span cy-data="field-creator-display" v-if="order.creator">{{
                  order.creator.name
                }}</span>
              </field>

              <many2one-field
                :label="$t('orders.columns.orderManagedBy')"
                v-model="order.follower"
                :fetch="$api.fetchUsers"
                reference="name"
                @click="(item) => $router.push('/user/' + item.id)"
                entity="user"
                :columns="userColumns"
                :hasFilter="true"
                :inline="true"
                cy-data="follower"
                :edit="editMode || followerEdition"
              ></many2one-field>

              <textarea-field
                cy-data="observation"
                :label="$t('common.observation')"
                v-model="order.observation"
                :inline="true"
              ></textarea-field>
            </div>

            <div class="column is-half">
              <workflow-history
                v-model="workflowComment"
                :entity="order"
                :entityName="WorkflowCodes.OS.NODE.OBJECT_NAME"
                :entityId="id"
                @updated="fetchData"
              ></workflow-history>
            </div>
          </div>
        </div>

        <div class="panel-block" v-if="currentTab === 1 && !isLoading">
          <div class="is-fullwidth">
            <div v-if="order.orderTemplate" class="columns">
              <div class="column is-half">
                <checkbox-field
                  v-if="editMode"
                  :label="$t('orders.displayAllPrestations')"
                  v-model="displayAllPrestations"
                />
              </div>
            </div>
            <flexi-table
              class="is-fullwidth"
              :loader="fetchPrestations"
              :columns="prestationColumns"
              :showIndex="true"
              @input="updatePrestationsModel"
              ref="prestationsTable"
              @sortBy="updateSort"
            >
              <template v-if="editMode" slot="extraHeaderRow">
                <td class="index"></td>
                <td v-for="(c, i) in prestationColumns" :key="i">
                  <template v-if="c.name !== 'total'">
                    <input
                      v-if="c.name !== 'cost' && c.name !== 'quantity'"
                      v-model="c.filter"
                      class="input"
                      type="text"
                    />
                    <input
                      v-else
                      v-model="c.filter"
                      class="input"
                      type="number"
                    />
                  </template>
                </td>
              </template>
              <template slot="firstDataRow">
                <tr
                  v-if="
                    !editMode &&
                    !prestations.some((p) => p.prestationOS.quantity != 0)
                  "
                >
                  <td colspan="10" style="text-align: center">
                    {{ $t("expressions.noPrestation") }} :)
                  </td>
                </tr>
              </template>
              <template slot="dataRows" slot-scope="{ item, index }">
                <tr
                  v-if="
                    !editMode &&
                    item.prestationOS &&
                    (item.prestationOS.quantity > 0 ||
                      item.prestationOS.quantity < 0)
                  "
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      item.category.parent
                        ? item.category.parent.name
                        : item.category.name
                    }}
                  </td>
                  <td>{{ item.category.parent ? item.category.name : "" }}</td>
                  <td>{{ item.reference.name }}</td>
                  <td>
                    <span :cy-data="`cy-os-prestation-code-${item.code}`">{{
                      item.code
                    }}</span>
                  </td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.unit.name }}</td>
                  <td class="has-text-right nowrap">
                    <span :cy-data="`cy-os-prestation-price-${item.code}`">{{
                      item.prestationSST.cost | priceEUR
                    }}</span>
                  </td>
                  <td class="has-text-right">
                    <span :cy-data="`cy-os-prestation-quantity-${item.code}`">{{
                      item.prestationOS.quantity | number(1)
                    }}</span>
                  </td>
                  <td class="has-text-right nowrap">
                    <span
                      :cy-data="`cy-os-prestation-total-price-${item.code}`"
                      >{{
                        (item.prestationSST.cost * item.prestationOS.quantity)
                          | priceEUR
                      }}</span
                    >
                  </td>
                </tr>
                <tr v-if="editMode && filter(item)">
                  <td class="index">{{ index + 1 }}</td>
                  <td class="filterable">
                    {{
                      item.category.parent
                        ? item.category.parent.name
                        : item.category.name
                    }}
                  </td>
                  <td class="filterable">
                    {{ item.category.parent ? item.category.name : "" }}
                  </td>
                  <td class="filterable">{{ item.reference.name }}</td>
                  <td class="filterable">{{ item.code }}</td>
                  <td class="filterable">{{ item.description }}</td>
                  <td class="filterable">{{ item.unit.name }}</td>
                  <td class="filterable has-text-right nowrap">
                    {{ item.prestationSST.cost | priceEUR }}
                  </td>
                  <td class="filterable">
                    <input
                      type="number"
                      step="0.5"
                      class="input"
                      v-model="item.prestationOS.quantity"
                      :cy-data="`cy-os-prestation-amount-${item.code}`"
                      :data-test="`sel-os-prestation-${index}`"
                    />
                  </td>
                  <td class="filterable has-text-right nowrap">
                    {{
                      (item.prestationSST.cost * item.prestationOS.quantity)
                        | priceEUR
                    }}
                  </td>
                </tr>
              </template>

              <template slot="lastDataRow">
                <tr
                  v-if="prestations.some((p) => p.prestationOS.quantity != 0)"
                >
                  <td colspan="8"></td>

                  <td class="has-text-right">
                    <span class="has-text-weight-bold">{{
                      totalQuantity | number(1)
                    }}</span>
                  </td>
                  <td class="has-text-right nowrap">
                    <span class="has-text-weight-bold">{{
                      total | priceEUR
                    }}</span>
                  </td>
                </tr>
              </template>
            </flexi-table>
          </div>
        </div>

        <div class="panel-block" v-if="currentTab === 2">
          <document-manager
            :objectName="WorkflowCodes.OS.NODE.OBJECT_NAME"
            :objectId="id"
            :deletable="canDeleteDocuments"
          ></document-manager>
        </div>

        <div class="panel-block" v-if="currentTab === 3 && !isLoading">
          <div class="is-fullwidth">
            <field :label="$t('orders.totalPrestation')" readonly>
              <span
                cy-data="field-prestation-amount-display"
                v-if="order.total"
                >{{ order.total | priceEUR }}</span
              >
            </field>

            <field :label="$t('orders.invoiced')" readonly>
              <span cy-data="field-already-invoiced-display">{{
                order.alreadyInvoiced | priceEUR
              }}</span>
            </field>
            <date-field
              :label="$t('orders.columns.deliveryDate')"
              v-model="order.deliveryDate"
              :edit="editMode || followerEdition"
            ></date-field>

            <text-field
              :label="$t('orders.columns.deliveryDuration')"
              v-model="order.executionPeriod"
              :edit="editMode || followerEdition"
            ></text-field>

            <!-- Edition of the deposit type -->
            <selector-field
              :label="$t('common.retention')"
              v-if="
                order.market.depositType ===
                  $t('warranties.retentionTypes.withThreshold') &&
                order.total < order.market.threshold &&
                order.statusWF.node.code !==
                  WorkflowCodes.OS.NODE.VALIDATION_PENDING_SST
              "
              v-model="order.depositType"
              :options="$api.fetchDepositType"
            />
            <field v-else :label="$t('common.retention')" readonly
              ><span>{{ order.depositType }}</span></field
            >

            <field
              v-if="
                order.depositType !=
                $t('warranties.retentionTypes.nonApplicable')
              "
              :label="$t('markets.columns.rgRate')"
              readonly
              ><span>{{ order.market.rgRate }} %</span></field
            >

            <date-field
              v-if="
                order.depositType !=
                $t('warranties.retentionTypes.nonApplicable')
              "
              :label="$t('orders.releaseDate')"
              v-model="order.releaseDate"
              :class="{ error: errors.has('mustBeAfter') }"
              :mustBeAfter="order.date"
              :edit="
                (editMode || followerEdition) &&
                userRoleOptions.includes($roleOptions.OS.EDIT_FINANCIAL_INFO)
              "
            />

            <Deposits
              :deposits="deposits"
              :add="addDeposit"
              :editable="
                order.depositType !==
                  $t('warranties.retentionTypes.nonApplicable') &&
                userRoleOptions.includes($roleOptions.OS.EDIT_FINANCIAL_INFO)
              "
              @added="fetchData"
            />
          </div>
          <div class="is-fullwidth">
            <button
              v-if="
                getRights($entitiesName.Attachement).create &&
                order.statusWF &&
                order.statusWF.node.nodeCode ==
                  WorkflowCodes.OS.NODE.VALIDATED &&
                !followerEdition
              "
              class="button is-primary is-pulled-right"
              @click="openCreateAttachementModal"
            >
              {{ $t("common.add") }}
            </button>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t("common.reference") }}</th>
                  <th>{{ $t("common.amount") }}</th>
                  <th>{{ $t("common.rg") }}</th>
                  <th>{{ $t("common.includingDepositUsed") }}</th>
                  <th>{{ $t("orders.releaseSituation") }}</th>
                  <th>{{ $t("common.status") }}</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!attachements || attachements.length == 0">
                  <td style="text-align: center" colspan="8">
                    {{ $t("expressions.noAttachment") }} :)
                  </td>
                </tr>
                <tr
                  v-for="(attachement, index) in attachements"
                  :key="index"
                  class="is-size-7"
                >
                  <td>#{{ index + 1 }}</td>
                  <td>{{ attachement.reference }}</td>
                  <td>{{ attachement.amount | priceEUR }}</td>
                  <td>{{ attachement.totalRg | priceEUR }}</td>
                  <td>{{ attachement.depositUsed | priceEUR }}</td>
                  <td>
                    <template v-for="(warranty, idx) in attachement.warranties">
                      <restricted-link
                        :key="warranty.id + '' + idx"
                        v-if="warranty.releaseMs"
                        container="span"
                        :entity="$entitiesName.MonthlyStatement"
                        :to="'/sst/situation/' + warranty.releaseMs.id"
                      >
                        {{ warranty.releaseMs.id }}-{{
                          warranty.releaseMs.market.edifice
                        }}
                        ({{ warranty.releaseMs.date | date }})
                      </restricted-link>
                    </template>
                  </td>
                  <td>
                    <span v-if="attachement.statusWF">
                      <router-link
                        :to="'/node/' + attachement.statusWF.node.id"
                        :title="attachement.statusWF.createdAt | date"
                        >{{ attachement.statusWF.node.name }}</router-link
                      >
                      ({{ attachement.statusWF.createdAt | date }})
                    </span>
                  </td>
                  <td>
                    <restricted-link
                      :entity="$entitiesName.Attachement"
                      :to="'/sst/attachement/' + attachement.id"
                      linkClass="button is-primary is-small"
                    >
                      <i class="fa fa-search"></i>
                    </restricted-link>
                  </td>
                  <td>
                    <form
                      class="inline-form is-pulled-right"
                      method="POST"
                      target="_blank"
                      :action="`${API}/attachement/${attachement.id}/pdf`"
                    >
                      <button type="submit" class="button is-success is-small">
                        {{ $t("common.download") }}
                      </button>
                      <input type="hidden" name="token" :value="authToken" />
                    </form>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <modal
      ref="createAttachementModal"
      @create="checkOSAttachmentCreationDate"
      :title="$t('attachments.attachmentCreation')"
    >
      <div class="columns">
        <div class="column is-half">
          <textarea-field
            :label="$t('common.observation')"
            v-model="attachement.observation"
            :inline="false"
            :edit="true"
          ></textarea-field>

          <checkbox-field
            :label="$t('common.dgd')"
            v-model="attachement.isDGD"
            :inline="true"
            :edit="true"
          ></checkbox-field>
        </div>
      </div>
    </modal>

    <input
      ref="osFileInput"
      type="file"
      :style="{ display: 'none' }"
      name="bpuFile"
      accept="text/csv"
      @change="importFile"
    />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
import Deposits from "../../components/Deposits.vue";
import { Workflows as WORKFLOWS } from "../../constants/Workflow";
import { globalLocale } from "../../i18n";

const initialSorting =
  "prestation.category.parent.name,prestation.category.name,prestation.description,ASC";

export default {
  name: "service-order",
  components: { Deposits },
  props: ["id"],
  data() {
    return {
      currentTab: 0,
      order: {},
      documentTypeColumns: {
        name: this.$t("common.name"),
      },
      nroColumns: {
        ipe_id_nro: "ID IPE",
        offer_id_nro: "ID Offre",
      },
      pmColumns: {
        ipe_id_pm: "ID IPE",
        offer_id_pm: "ID Offre",
      },
      marketColumns: {
        "project.name": "Projet",
        "agency.company.name": "Etablissement",
        edifice: this.$t("common.edifice"),
        maxAmountDC4: "Montant Max DC4 HT",
        eotpST: "eOTP ST",
        "bpu.name": "BPU",
        tva: "TVA",
        depositType: "RG/Caution",
        paymentType: "Paiement direct/Entreprise",
        contractNumber: this.$t("markets.columns.contractNumber"),
      },
      orderTemplateColumns: {
        name: this.$t("expressions.templateName"),
      },
      imputationColumns: {
        eotp: this.$t("common.eotp"),
        description: this.$t("common.description"),
      },
      prestationColumns: [
        {
          title: this.$t("common.category"),
          name: "category",
          accessor: "category.parent.name",
          filter: "",
          sort: "prestation.category.parent.name",
          class: "sortable",
        },
        {
          title: this.$t("common.subcategory"),
          name: "subcategory",
          accessor: "category.name",
          filter: "",
          class: "sortable",
          sort: "prestation.category.name",
        },
        {
          title: this.$t("common.reference"),
          name: "reference",
          accessor: "reference.name",
          filter: "",
          sort: "prestation.reference.name",
          class: "sortable",
        },
        {
          title: this.$t("common.code"),
          name: "code",
          accessor: "code",
          filter: "",
          sort: "prestation.code",
          class: "sortable",
        },
        {
          title: this.$t("common.description"),
          name: "description",
          accessor: "description",
          filter: "",
          sort: "prestation.description",
          class: "sortable",
        },
        {
          title: this.$t("common.unit"),
          name: "unit",
          accessor: "unit.name",
          filter: "",
          sort: "prestation.unit.name",
          class: "sortable",
        },
        {
          title: this.$t("prestations.columns.unitPrice"),
          name: "cost",
          accessor: "prestationSST.cost",
          filter: "",
          sort: "cost",
          class: "has-text-centered sortable",
        },
        {
          title: this.$t("common.quantity"),
          name: "quantity",
          accessor: "prestationOS.quantity",
          filter: "",
          class: "has-text-centered",
        },
        {
          title: this.$t("common.total"),
          name: "total",
          accessor: "",
          filter: "",
          class: "has-text-centered",
        },
      ],
      documentColumns: {
        id: "Id",
        "type.name": "Type",
        name: this.$t("common.name"),
      },
      attachementColumns: {
        id: "Id",
        reference: this.$t("common.reference"),
      },
      userColumns: {
        name: this.$t("common.name"),
        mail: "E-Mail",
        "role.name": "Role",
        service: "Service",
      },
      documentName: "",
      prestations: [],
      documents: {},
      attachement: {},
      attachements: [],
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      isLoading: false,
      sendToOracleLoader: false,
      workflowComment: "",
      WorkflowCodes: WORKFLOWS,
      followerEdition: false,
      displayAllPrestations: false,
      sorting: initialSorting,
      deposits: {},
      globalLocale: globalLocale,
    };
  },
  computed: {
    ...mapGetters({
      editMode: "states/isEdition",
      getRights: "auth/getRights",
      user: "auth/getUser",
      userRoleOptions: "auth/getOptions",
      ActiveProfile: "auth/getActiveProfile",
    }),
    totalQuantity() {
      let total = 0;
      this.prestations.forEach(
        (p) => (total += Number(p.prestationOS.quantity))
      );
      return total;
    },
    total() {
      let total = 0;
      this.prestations.forEach(
        (p) => (total += Number(p.prestationOS.quantity * p.prestationSST.cost))
      );
      return total;
    },
    canDeleteDocuments() {
      if (
        this.userRoleOptions.includes("serviceorders.deletion_after_validation")
      ) {
        return true;
      }
      return !!(
        this.order.statusWF &&
        [
          WORKFLOWS.OS.NODE.DRAFT,
          WORKFLOWS.OS.NODE.VALIDATION_PENDING_CDP,
          WORKFLOWS.OS.NODE.VALIDATION_PENDING_SST,
        ].indexOf(this.order.statusWF.node.nodeCode) > -1
      );
    },
    projectId() {
      return this.order.project ? this.order.project.id : 0;
    },
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    // eslint-disable-next-line
    "order.orderTemplate": function (val) {
      if (!this.editMode) {
        return;
      }

      // Skip alert if removing the template filter as no filter will be applied.
      if (!val) {
        return;
      }

      this.$awn.confirm(
        "Sauvegarder la commande avant d'éditer les prestations !",
        () => {},
        false,
        {
          labels: {
            confirm: "Attention",
          },
          icons: {
            confirm: "info",
          },
        }
      );
    },
    sorting() {
      this.$refs.prestationsTable.fetch();
    },
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.followerEdition = false;
      axios
        .get(`/order/${this.id}`)
        .then(async (response) => {
          this.order = response.data;
          await this.$store.dispatch("states/setEdition", false);
        })
        .then(() => {
          axios.get(`/order/${this.id}/deposits`).then((res) => {
            res.data.forEach((deposit) => {
              if (deposit) {
                this.$set(this.deposits, deposit.id, {
                  ...deposit,
                  attachements: {},
                });
              }
            });
          });
        })
        .then(() => {
          axios.get(`/order/${this.id}/attachements`).then((response) => {
            this.attachements = response.data.sort((a, b) =>
              a.reference.localeCompare(b.reference)
            );
            response.data.forEach((att) => {
              att.warranties.forEach((warranty) => {
                if (warranty.deposit && this.deposits[warranty.deposit.id]) {
                  if (
                    !this.deposits[warranty.deposit.id].attachements[att.id]
                  ) {
                    this.deposits[warranty.deposit.id].attachements[att.id] = {
                      reference: att.reference,
                      amount: warranty.depositUsed,
                    };
                  } else {
                    this.deposits[warranty.deposit.id].attachements[
                      att.id
                    ].amount += warranty.depositUsed;
                  }
                }
              });
            });
          });
        })
        .catch((e) => {
          if (!this.order_id) {
            this.$router.push({ name: "ServiceOrderList" });
          }
          // eslint-disable-next-line
          console.error(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getPrestationOS() {
      return this.prestations.map((p) => {
        const prestation = {
          ...p,
        };
        if (!p.prestationOS) {
          prestation.prestationOS = {
            prestationSST: p.prestationSST,
            quantity: "0",
            order: this.order,
            id: null,
          };
        }
        return prestation;
      });
    },
    fetchPrestations(callback) {
      axios
        .get(`/order/${this.id}/prestations?sort=${this.sorting}`)
        .then((res) => {
          this.prestations = res.data;
          this.updatePrestationsModel(this.getPrestationOS());
          if (typeof callback === "function") {
            callback(this.getPrestationOS());
          }
        });
    },
    fetchFilteredOrderTemplate(p) {
      const params = p;
      if (!params.filters) {
        params.filters = {};
      }
      params.filters.bpuTemplateId = this.order.bpuTemplate.id;
      return this.$api.fetchOsTemplates(params);
    },
    fetchFilteredImputation(p) {
      const params = p;
      if (!params.filters) {
        params.filters = {};
      }
      params.filters.project = this.order.project.name;
      params.filters.active = true;
      return this.$api.fetchImputations(params);
    },
    updatePrestationsModel(val) {
      this.prestations = val;
    },
    filter(item) {
      if (
        this.order.orderTemplate !== null &&
        !this.displayAllPrestations &&
        !item.isInTemplate &&
        +item.prestationOS.quantity === 0
      ) {
        return false;
      }

      return this.prestationColumns.every((p) => {
        const value = this.getProperty(item, p.accessor);

        // Escape all the characters provided by the user that could be interpreted in the Regex
        const filter = p.filter.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

        // Prepare the regex
        const rx = new RegExp(`.*${filter}.*`, "gi");

        // Evaluate
        return rx.test(value);
      });
    },
    getProperty(obj, accessor) {
      const props = accessor.split(".");
      let result = obj;
      for (let i = 0; i < props.length; i += 1) {
        const p = props[i];
        if (!result[p]) {
          return "";
        }
        result = result[p];
      }
      return result;
    },
    save() {
      return this.$validator.validateAll().then((result) => {
        if (result) {
          const order = {
            ...this.order,
            prestationOSs: this.prestations.filter(
              (p) =>
                (p.prestationOS.quantity !== "0" &&
                  p.prestationOS.quantity !== "") ||
                p.prestationOS.id !== null
            ),
          };
          return axios
            .put(`/order/${this.id}`, order)
            .then(async (response) => {
              if (response.data.success) {
                await this.fetchData();
              }
            });
        }
        return Promise.resolve();
      });
    },
    edit() {
      this.$store.dispatch("states/setEdition", true);
    },
    cancel() {
      this.fetchData();
    },
    openCreateAttachementModal() {
      this.$refs.createAttachementModal.open();
    },
    closeCreateAttachementModal() {
      this.attachement = {};
      this.$refs.createAttachementModal.close();
      axios.get(`/order/${this.id}/attachements`).then((response) => {
        this.attachements = response.data.content;
      });
    },
    createAttachement() {
      if (this.attachement.id === undefined) {
        this.attachement.order = this.order;
        axios.post("/attachement", this.attachement).then((response) => {
          if (response.data.success) {
            this.closeCreateAttachementModal();
            this.fetchData();
          }
        });
      } else {
        axios
          .put(`/attachement/${this.attachement.id}`, this.attachement)
          .then((response) => {
            if (response.data.success) {
              this.closeCreateAttachementModal();
            }
          });
      }
    },
    importClick() {
      // eslint-disable-next-line
      this.$refs.osFileInput.click();
    },
    importFile(e) {
      if (e.target.files[0]) {
        const form = new FormData();
        form.set("file", e.target.files[0]);
        axios
          .post(`/order/${this.id}/import`, form, {
            headers: { "Content-Type": "charset=UTF-8" },
          })
          .then(() => {
            this.$refs.prestationsTable.fetch();
            this.fetchData();
            // eslint-disable-next-line
            alert("Import effectué avec succès");
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.error(err);
          });
      }
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cette commande ?",
        () =>
          axios.delete(`/order/${this.id}/delete`).then((response) => {
            if (response.data.success) {
              this.$router.replace("/sst/orders");
            }
          })
      );
    },
    checkOSAttachmentCreationDate() {
      if (!this.order.lastAttachment) {
        this.createAttachement();
        return;
      }

      const daysSinceLastAttachment = moment().diff(
        moment(this.order.lastAttachment.createdAt),
        "days"
      );
      if (daysSinceLastAttachment > 30) {
        this.createAttachement();
        return;
      }
      this.$awn.confirm(
        "Attention vous avez déjà attaché cette commande ce mois-ci. Souhaitez-vous continuer ?",
        () => {
          this.createAttachement();
        },
        () => {
          this.$refs.createAttachementModal.close();
        }
      );
    },
    updateSort(newValue) {
      if (newValue === "initialValue") {
        this.sorting = initialSorting;
        return;
      }

      this.sorting = newValue;
    },
    addDeposit(deposit) {
      return axios.post(`order/${this.id}/deposit`, deposit);
    },
    sendToOracle() {
      this.$awn.confirm(
        "Êtes-vous sûr de vouloir envoyer cette commande à Oracle ? Assurez-vous que la commande n'est pas déjà en cours d'envoi !",
        () => {
          this.sendToOracleLoader = true;
          return axios.post(`oracle/order/${this.id}`).finally(() => {
            this.sendToOracleLoader = false;
          });
        }
      );
    },
  },
};
</script>

<style lang='scss' scoped>
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}
.panel-block {
  align-items: flex-start;
}

.is-fullwidth {
  width: 100%;
}

.inline-form {
  display: inline;
}

tr {
  td.filterable {
    width: 12%;
  }
}

.deposits {
  padding: 10px;
  border-radius: 5px;
}
.deposits-header {
  display: flex;
  & > * {
    margin-right: 5px;
  }
}
</style>
